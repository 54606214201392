import { Injectable } from '@angular/core';
import { HttpHeaders, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Cliente } from 'app/main/shared/model/cliente/cliente.model';
import { SGF_API } from '../../../../../shared/api/sgf.api';
import { ObservacaoCliente } from 'app/main/shared/model/observacao-cliente/observacao-cliente.model';
import { UploadSPC } from 'app/main/shared/components/cliente-full/dados-cliente/dados-cliente.component';
import { ResponseAPI } from 'app/main/infinitylabs/shared/model/responseAPI';

@Injectable({
  providedIn: 'root'
})
export class ClienteService {

  private posto: string;
  host = `${SGF_API}` + 'sgf/api/public/cliente';
  hostCliente = `${SGF_API}` + 'cliente/api/public/cliente';
  hostObservacaoCliente = `${SGF_API}` + 'sgf/api/public/observacao-cliente';
  private urlArquivoSPC = `${SGF_API}` + 'sgf/api/public/arquivo-spc';


  constructor(private httpClient: HttpClient) { }

  findAll(): Observable<any> {
    this.checkIfHasPosto();

    return this.httpClient.get<Cliente[]>(`${this.host}?size=1000&${this.posto}`, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );

  }

  findClienteByNome(nomeCliente: string): Observable<any> {
    this.checkIfHasPosto();

    return this.httpClient.get<Cliente[]>(`${this.host}?size=100&${this.posto}&nomeCliente=${nomeCliente}`, this.httpOptions())
        .pipe(
            catchError(this.handleError)
        );

  }

  validarPessoaJaCadastrada(documentoPessoaCommand: any): Observable<any> {
    const body = JSON.stringify(documentoPessoaCommand);
    return this.httpClient.post(`${this.host}/validar-pessoa-ja-cadastrada/`, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  validarPessoaJaCadastradaNome(documentoPessoaCommand: any): Observable<any> {
    let documentoPessoa = {
      documento: documentoPessoaCommand
    };
    return this.httpClient.post(`${this.host}/validar-pessoa-ja-cadastrada-nome/`, documentoPessoa, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  page(size: number, page: number): Observable<any> {
    this.checkIfHasPosto();
   
    return this.httpClient.get(`${this.host}?size=${size}&page=${page}&${this.posto}&sort=-id&joinRota=true`, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    ); 

  }

  pageSitucao(size: number, page: number, situacao: string): Observable<any> {
    this.checkIfHasPosto();

    return this.httpClient.get(`${this.host}?size=${size}&page=${page}&situacaoCliente=in:${situacao}&${this.posto}&sort=-id&joinRota=true`, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
  
  }


  pageDocumentoCliente(size: number, page: number, documento: string): Observable<any> {
    this.checkIfHasPosto();

    return this.httpClient.get(`${this.host}?filters=%26documentoCliente%3D%25${documento}%25&size=${size}&page=${page}&${this.posto}&sort=-id&joinRota=true`, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
 
  }

  pageNomeCliente(size: number, page: number, nomeCliente: string): Observable<any> {
    this.checkIfHasPosto();
   
    return this.httpClient.get(`${this.host}?filters=%26nomeCliente%3D%25${nomeCliente}%25&size=${size}&page=${page}&${this.posto}&sort=-id&joinRota=true`, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
    
  }

  pageNomeBusca(size: number, page: number, nomeBusca: string): Observable<any> {
    this.checkIfHasPosto();
   
    return this.httpClient.get(`${this.host}?filters=%26nomeBusca%3D%25${nomeBusca}%25&size=${size}&page=${page}&${this.posto}&sort=-id&joinRota=true`, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
    
  }
  
  pageEndereco(size: number, page: number, endereco: string): Observable<any> {
    this.checkIfHasPosto();
   
    return this.httpClient.get(`${this.host}?filters=%26endereco%3D%25${endereco}%25&size=${size}&page=${page}&${this.posto}&sort=-id&joinRota=true`, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
    
  }
  
  pageByCEP(size: number, page: number, cep: string): Observable<any> {
    this.checkIfHasPosto();
   
    return this.httpClient.get(`${this.host}?filters=%26cep%3D%25${cep}%25&size=${size}&page=${page}&${this.posto}&sort=-id&joinRota=true`, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );
    
  }

  pageDescricaoRota(size: number, page: number, descricaoRota: string): Observable<any> {
    this.checkIfHasPosto();
    return this.httpClient.get(`${this.host}?filters=%26rota%3D%25${descricaoRota}%25&size=${size}&page=${page}&${this.posto}&sort=-id&joinRota=true`, this.httpOptions())
      .pipe(catchError(this.handleError));
  }

  addCliente(cliente: Cliente): Observable<any> {
    const body = JSON.stringify(cliente);
    return this.httpClient.post(this.host, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  editCliente(cliente: Cliente): Observable<any> {
    const body = JSON.stringify(cliente);
    console.log('Requisição HTTP PUT - corpo:', body);
    console.log('Enviando requisição para o endpoint:', this.host);
    return this.httpClient.put(this.host, body, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  findById(id: number): Observable<any> {
    return this.httpClient.get<Cliente[]>(`${this.host}/${id}`, this.httpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  inativarCliente(cliente: any): Observable<any> {
    return this.httpClient.put<any>(`${this.host}/atualizar-situacao-cliente`, cliente, this.httpOptions()).pipe(
      catchError(this.handleError)
    );
  }
  
  colocarClienteEmCobrancaJudicial(cliente: any): Observable<any> {
    return this.httpClient.put<any>(`${this.host}/atualizar-situacao-cliente`, cliente, this.httpOptions()).pipe(
      catchError(this.handleError)
    );
  }

  putClienteSpc(cliente: Cliente): Observable<any> {
    const body = JSON.stringify(cliente);

    const funcionario = JSON.parse(sessionStorage.getItem('funcionario'));
    const usuarioSGF = funcionario.nomeUser;
    const idSGF = funcionario.idUsuario;
    const dateSPC = new Date().getTime();

    return this.httpClient.put<Cliente>(`${this.host}/adicionaClienteSerasa?usuario=${usuarioSGF}&id=${idSGF}&dataSPC=${dateSPC}`, body, this.httpOptions()).pipe(
      catchError(this.handleError)
    );
  }

  removeClienteSpc(cliente: Cliente): Observable<any> {
    const body = JSON.stringify(cliente);
    return this.httpClient.put<Cliente>(`${this.host}/removeClienteSerasa`, body, this.httpOptions()).pipe(
      catchError(this.handleError)
    );
  }

  inativarRenovacaoAutomatica(cliente: Cliente): Observable<any> {
    const body = JSON.stringify(cliente);

    const funcionario = JSON.parse(sessionStorage.getItem('funcionario'));
    const usuarioSGF = funcionario.nomeUser;
    const idSGF = funcionario.idUsuario;
    const dateSPC = new Date().getTime();

    return this.httpClient.put<Cliente>(`${this.host}/inativarRenovacaoAutomatica?usuario=${usuarioSGF}&id=${idSGF}&dataSPC=${dateSPC}`, body, this.httpOptions()).pipe(
      catchError(this.handleError)
    );
  }

  reativarRenovacaoAutomatica(cliente: Cliente): Observable<any> {
    const body = JSON.stringify(cliente);
    return this.httpClient.put<Cliente>(`${this.host}/reativarRenovacaoAutomatica`, body, this.httpOptions()).pipe(
      catchError(this.handleError)
    );
  }

  alterarSituacaoReceita(idCliente: number, situacao: number): Observable<any> {
    return this.httpClient.get(`${this.host}/alterar-situacao-receita/${idCliente}/${situacao}`, this.httpOptions()).pipe(
        catchError(this.handleError)
    );
  }

  relatorioInadimplencia(): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': ''
    });
    return this.httpClient.get(`${this.hostCliente}/imprimir-relatorio-inadimplencia`, { responseType: 'blob', headers: httpHeaders });
  }

  relatorioClientesPorFiador(): Observable<any>  {
     const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': ''
    });
    return this.httpClient.get(`${this.host}/imprimir-relatorio-clientes-por-fiador`, { responseType: 'blob', headers: httpHeaders });
  }

  relatorioInativos(datas: any, tipoContrato: any, posto: any, rota, usuario: any, opcao: any): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': ''
    });
    // tslint:disable-next-line:max-line-length
    return this.httpClient.get(`${this.hostCliente}/imprimir-relatorio-inativos?datas=${datas}&boleto=${tipoContrato}&posto=${posto}&rota=${rota}&usuario=${usuario}&opcao=${opcao}`, { responseType: 'blob', headers: httpHeaders });
  }

  relatorioRenovacaoCadastral(datas: any, idPosto: any, usuario: any, opcao: boolean): Observable<any>  {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': ''
    });
    return this.httpClient.get(`${this.hostCliente}/relatorio-clientes-renovados?datas=${datas}&posto=${idPosto}&usuario=${usuario}&opcao=${opcao}`,
        { responseType: 'blob', headers: httpHeaders });

  }

  getFichaCadastralCliente(clienteId: number): any {
    return this.httpClient.get(`${this.host}/ficha-cadastral/${clienteId}`, { responseType: 'blob' });
  }

  generatePaymenteAuthorization(clienteID: number, formaDePagamento: string): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': ''
    });
    return this.httpClient.get(`${this.host}/${clienteID}/gerar-autorizacao-de-pagamento?formaDePagamento=${formaDePagamento}`, { responseType: 'blob', headers: httpHeaders });
  }

  checkIfHasPosto(): void {
    if (sessionStorage.getItem('funcionario')) {
      const user = JSON.parse(sessionStorage.getItem('funcionario'));
      this.posto = user.idPosto ? 'postoId=' + user.idPosto : '';
    }
  }

  httpOptions(token?: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': ''
      })
    };
  }

  private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  }

  findAllObservacaoCliente(): Observable<any> {
    return this.httpClient.get<ObservacaoCliente[]>(`${this.hostObservacaoCliente}?size=1000`, this.httpOptions())
    .pipe(
      catchError(this.handleError)
    );

  }

  getValorLiquidoCliente(clienteId: number): Observable<any>  {
    return this.httpClient.get(`${this.host}/retorna-valor-liquido-cliente/${clienteId}`, this.httpOptions()).pipe(catchError(this.handleError));
  }

  uploadArquivoSPC(dto: UploadSPC): Observable<ResponseAPI> {
    return this.httpClient.post<ResponseAPI>(`${this.host}/confirmar-upload-spc`, dto);
  }

  imprimirArquivoSPC(clienteId: number): Observable<any> {
    return this.httpClient.get(`${this.urlArquivoSPC}/storage/cliente/${clienteId}`, { responseType: 'blob' });
  }

  isClienteRenovado(clienteId: number): Observable<any>  {
    return this.httpClient.get(`${this.host}/is-cliente-renovado/${clienteId}`, this.httpOptions()).pipe(catchError(this.handleError));
  }
}
