import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormArray, FormControl, FormBuilder } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { Mask } from '@fagnerlima/ng-mask';

@Component({
  selector: 'app-cliente-full-fiador',
  templateUrl: './cliente-full-fiador.component.html',
  styleUrls: ['./cliente-full-fiador.component.scss'],
  animations: fuseAnimations
})
export class ClienteFullFiadorComponent implements OnInit {

  @Input() cliente: FormGroup;
  maskCpf = new Mask('000.000.000-00');

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    console.log(this.cliente)
  }

  get fiadoresForms(): FormArray {
    return this.cliente.get('listReferencia.referenciaFiador') as FormArray
  }

  get addressForms(): FormArray {
    return this.cliente.get('listReferencia').get('referenciaFiador').get('listEndereco') as FormArray;
  }


  delete(index: number): void {
    this.addressForms.removeAt(index);

  }

  buildAddress(): FormArray {
    return this.formBuilder.array([
      this.formBuilder.group({
        id: new FormControl(null),
        cep: new FormControl(null),
        logradouro: new FormControl(null),
        numero: new FormControl(null),
        latitude: new FormControl(null),
        longitude: new FormControl(null),
        bairro: new FormControl(null),
        municipio: new FormControl(null),
        complemento: new FormControl(null),
        estado: new FormControl(null),
        pontoReferencia: new FormControl(null),
        tipoEndereco: new FormControl(null)
      })
    ]);

  }

  addFiador(required?: boolean): void {
    const fiador = this.formBuilder.group({
      id: [],
      type: ['fi'],
      nomeFiador: [null],
      cpfFiador: [null],
      listEndereco: this.buildAddress(),
      listaEndereco: [],
      nomeConjuge: [null],
      cpfConjuge:  [null]
    });
    this.fiadoresForms.push(fiador);
  };

  deleteFiador(index: number): void {
    this.fiadoresForms.removeAt(index);

  }

  getErrorMessage(field: string): string {
    return `O campo ${field} é obrigatório`;
  }

}
