import {AfterViewInit, Component, Inject, OnInit, OnChanges, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {FormArray, FormBuilder, FormControl, FormGroup, RequiredValidator, Validators} from '@angular/forms';
import {SegmentsService} from '../../../global/components/segments/services/segments.service';
import {ListUnididadeFederacao} from '../../model/uf/ufs.model';
import {MessageDialogComponent} from '../message-dialog/message-dialog.component';
import {CustomersModel} from '../../model/customers/customers-model';
import {Employee} from '../../model/employee/employee.model';
import {ClienteService} from 'app/main/customers/components/cliente/services/cliente.service';

import {ContractsService} from 'app/main/contracts/services/contracts.service';
import {Contract} from '../../model/contract/contract.model';
import {GlobalService} from 'app/main/global/services/global.service';
import {PersonPf} from '../../model/person/person-pf.model';
import {PostsService} from 'app/main/global/components/posts/services/posts.service';
import {Posto} from '../../model/posto/posto.model';
import { Cliente } from '../../model/cliente/cliente.model';
import { ReferenciasPixFormComponent } from '../referencias-pix-form/referencias-pix-form.component';
import { TimeMensagem } from '../../model/enums/time-mensagem.enum';
import { UtilsService } from 'app/shared/util/utils.service';
import { UploadSPC } from './dados-cliente/dados-cliente.component';
import { ResponseAPI } from 'app/main/infinitylabs/shared/model/responseAPI';
import { HttpErrorResponse } from '@angular/common/http';

declare var google: any;

export interface DialogData {
  cliente: CustomersModel;
  action: string;
}

@Component({
  selector: 'app-cliente-full',
  templateUrl: './cliente-full.component.html',
  styleUrls: ['./cliente-full.component.scss'],
})
export class ClienteFullComponent implements OnInit, OnChanges {
  @ViewChild(ReferenciasPixFormComponent) referenciasPixFormComponent: ReferenciasPixFormComponent;
  private static RENDA_MENSAL_PADRAO = 15000;

  titleHeader: string;

  dialogRef: any;
  selected: any;
  dialogMessages;
  step = 0;
  cliente: FormGroup;
  showPanelId: Boolean;
  listTelefone: any;
  listEndereco: any;
  listReferenciaPessoal: any;
  listReferencias = [];
  telephones: FormArray;
  rows: Array<Contract>;
  valorLiquido: any;

  segmentList: any[] = [];
  observacoesList: any[] = [];
  ufs = ListUnididadeFederacao;
  municipio: any;
  listFuncionarios: any = [];
  editedCaptacao: any;
  loggedEmployee: Employee;
  referenciaPessoal: any;
  referenciaComercial: any;
  referenciaBancaria: any;
  referenciaFiador=[];
  listReferencia = [];
  listContaBancaria = [];
  listSocio = [];
  isCpf: boolean = true;
  dataNascimento: any;
  data: Date = new Date;
  disabledButton: boolean;
  captadorPosto: Posto = new Posto({});
  situacaoCliente: any;

  referenciaCliente: Cliente;
  isCLientePostoPix: boolean = false;
  referenciaPixFormInvalido: boolean = false;
  isEditar = false;
  uploadSPC: UploadSPC;

  constructor(
    private formBuilder: FormBuilder,
    public matDialogRef: MatDialogRef<ClienteFullComponent>,
    public _matDialog: MatDialog,
    private segmentService: SegmentsService,
    private clienteService: ClienteService,
    private globalService: GlobalService,
    private contractService: ContractsService,
    public snackBar: MatSnackBar,
    private postoService: PostsService,
    private utilsService: UtilsService,
    @Inject(MAT_DIALOG_DATA) public _data: any) {
    if (this._data.action === 'update' && this._data.customer.pessoa.type === 'pj') {
      this.isCpf = false;
    }

    this.situacaoCliente = this._data.customer && this._data.customer.situacaoCliente ? this._data.customer.situacaoCliente : null;
    this.referenciaCliente = this._data.customer ? this._data.customer : null;
    this.isCLientePostoPix = this._data.customer ? this._data.customer.posto.parametroPosto.pixBoleto : null;

    this.loadFormControlDefaults();

    this.segmentService.findAll().subscribe(data => {
      this.segmentList = data.object.content;
    });

    this.clienteService.findAllObservacaoCliente().subscribe((ondata) => {
      this.observacoesList = ondata.object;
    });

    if(this._data.action ==='update'){
      this.isEditar = true;
      this.clienteService.getValorLiquidoCliente(this._data.customer.id).subscribe((ondata) =>{
        this.valorLiquido = ondata.object;
      });
    }    
  }

  loadFormControlDefaults(): void {
    switch (this._data.action) {
      case 'new': {
        this.changeTitleHeader();
        this.listEndereco = new CustomersModel({}).listEndereco;
        this.listTelefone = new CustomersModel({}).listTelefone;
        this.listSocio = [new PersonPf()];
        this.listReferenciaPessoal = new CustomersModel({}).referenciaPessoal;
        this.listReferencia = [];

        break;
      }
      case 'update': {
        const referenciasPessoais = [];
        this.changeTitleHeader();
        this.listEndereco = this._data.customer.pessoa.listEndereco;
        this.listTelefone = this._data.customer.pessoa.listTelefone;
        this.listContaBancaria = this._data.customer.pessoa.listContaBancaria;
        this.listSocio = this._data.customer.pessoa.listSocio ? this._data.customer.pessoa.listSocio : [new PersonPf()];
        this.listReferencias = this._data.customer.listReferencia;
        this._data.customer.listReferencia.forEach(r => {
          if (r.type === 'rp') {
            referenciasPessoais.push(r);
          }
          this.listReferenciaPessoal = referenciasPessoais;
        });
        break;
      }
    }
  }

  ngOnInit(): void {
    this.checkIfHasPosto();
    this.setFormGroup();
  }

  ngOnChanges(): void {
    console.log(this.cliente)
  }

  changeTitleHeader(): void {
    if (this._data.action === 'new') {
      this.titleHeader = 'Novo Cliente';
    } else {
      this.titleHeader = 'Editar Cliente';
    }
  }

  getIsCpfEvent(value): void {
    this.cliente.get('pessoa.isCpf').setValue(value);
    this.isCpf = value;
    this.setFormGroup();
  }

  setFormGroup(): void {
    this.cliente = this.formBuilder.group({
      id: this._data.customer ? this._data.customer.id : null,
      atividadeComercial: [this._data.customer ? (this._data.customer.atividadeComercial ? this._data.customer.atividadeComercial.descricao : null) : null],
      pessoa: this.formBuilder.group({
        id: this._data.customer ? this._data.customer.pessoa.id : null,
        isCpf: this.isCpf,
        nome: [this._data.customer ? this._data.customer.pessoa.nome : null, this.isCpf? Validators.required:null],
        nomeBeneficiario:[this._data.customer ? this._data.customer.pessoa.nomeBeneficiario : null, this.isCpf? Validators.required:null],
        nomeFantasia: [this._data.customer ? this._data.customer.pessoa.nomePessoa : null, (!this.isCpf ? Validators.required : null)],
        razaoSocial: [this._data.customer ? this._data.customer.pessoa.razaoSocial : null],
        sexo: [this._data.customer ? this._data.customer.pessoa.sexo : null],
        dataNascimento: [this._data.customer ? new Date(this._data.customer.pessoa.dataNascimento ? this._data.customer.pessoa.dataNascimento : '') : null],
        cpf: [this._data.customer ? this._data.customer.pessoa.documento : null, (this.isCpf ? Validators.required : null)],
        cnpj: [this._data.customer ? this._data.customer.pessoa.documento : null, (!this.isCpf ? Validators.required : null)],
        rg: [this._data.customer ? this._data.customer.pessoa.rg : null, (this.isCpf ? Validators.required : null)],
        rgUf: [this._data.customer ? this._data.customer.pessoa.rgUf : null],
        rgOrgaoEmissor: [this._data.customer ? this._data.customer.pessoa.rgOrgaoEmissor : null],
        rgDataEmissao: [this._data.customer ? new Date(this._data.customer.pessoa.rgDataEmissao ? this._data.customer.pessoa.rgDataEmissao : '') : null, Validators.required],
        estadoCivil: [this._data.customer ? this._data.customer.pessoa.estadoCivil : null],
        ocupacaoProfissional: [this._data.customer && this._data.customer.pessoa.ocupacaoProfissional ? this._data.customer.pessoa.ocupacaoProfissional : 'Comerciante', (this.isCpf ? Validators.required : null)],
        rendaMensal: [this._data.customer && this._data.customer.pessoa.rendaMensal ? this._data.customer.pessoa.rendaMensal : ClienteFullComponent.RENDA_MENSAL_PADRAO, (this.isCpf ? Validators.required : null)],
        porteSalario: [this._data.customer && this._data.customer.pessoa.porteSalario ? this._data.customer.pessoa.porteSalario : ClienteFullComponent.RENDA_MENSAL_PADRAO, (this.isCpf ? Validators.required : null)],
        faturamentoAnual: [this._data.customer && this._data.customer.pessoa.faturamentoAnual ? this._data.customer.pessoa.faturamentoAnual : ClienteFullComponent.RENDA_MENSAL_PADRAO, (this.isCpf ? Validators.required : null)],
        naturalidade: [this._data.customer && this._data.customer.pessoa.naturalidade ? this._data.customer.pessoa.naturalidade : null],
        nomeMae: [this._data.customer && this._data.customer.pessoa.nomeMae ? this._data.customer.pessoa.nomeMae : null],
        nomePai: [this._data.customer && this._data.customer.pessoa.nomePai ? this._data.customer.pessoa.nomePai : null],
        listTelefone: this.buildPhone(),
        listEndereco: this.buildAddress(this.listEndereco, true),
        listContaBancaria: this.buildContaBancaria(),
        listSocio: this.buildSocio(this.listSocio ? this.listSocio : null),
        email: [this._data.customer ? this._data.customer.pessoa.email : null, null],
        type: []
      }),
      turno: [this._data.customer ? this._data.customer.turno : null, Validators.required],
      listReferencia: this.referencia(this.listReferencias),
      referenciaPessoal: this.buildReferenciaPessoal(this.listReferenciaPessoal ? this.listReferenciaPessoal : []),
      situacaoCliente: [this._data.customer ? this._data.customer.situacaoCliente : 'NOVO'],
      apelidoCliente: [this._data.customer ? this._data.customer.apelidoCliente : null],
      instagram: [this._data.customer ? this._data.customer.instagram : null, null],
      instagramProfissional: [this._data.customer ? this._data.customer.instagramProfissional : null, null],
      optantePorAproximacao: [this._data.customer ? this._data.customer.optantePorAproximacao : false],
      situacaoReceitaEnum: [this._data.customer ? this._data.customer.situacaoReceitaEnum : null],
      observacao: [this._data.customer ? this._data.customer.observacao : null],
      dataCadastro: [this._data.customer && this._data.customer.dataCadastro ? new Date(this._data.customer.dataCadastro) : null],
      dataUltimaRenovacaoCadastral: [this._data.customer && this._data.customer.dataUltimaRenovacaoCadastral ? new Date(this._data.customer.dataUltimaRenovacaoCadastral) : null],
      dataProximaRenovacaoCadastral: [this._data.customer && this._data.customer.dataProximaRenovacaoCadastral ? new Date(this._data.customer.dataProximaRenovacaoCadastral) : null],
      nomeConjuge: [this._data.customer ? this._data.customer.nomeConjuge : null],
      cpfConjuge: [this._data.customer ? this._data.customer.cpfConjuge : null],
      observacaoCliente: [this._data.customer ? (this._data.customer.observacaoCliente ? this._data.customer.observacaoCliente.descricao : null) : null],
      politicamenteExposto: [this._data.customer && this._data.customer.politicamenteExposto ? this._data.customer.politicamenteExposto : false],
      procuradoInterpol: [this._data.customer && this._data.customer.procuradoInterpol ? this._data.customer.procuradoInterpol : false],
      doadorOnu: [this._data.customer && this._data.customer.doadorOnu ? this._data.customer.doadorOnu : false],
      fileName: [this._data.customer && this._data.customer.arquivoSPC ? this._data.customer.arquivoSPC.fileName : null , this._data.customer && this._data.customer.situacaoCliente === 'NOVO' ? Validators.required : null],
      file: [null, null]
    });
  }

  buildPhone(): FormArray {
    const phone = this.listTelefone.map(v => {
      return this.formBuilder.group({
        ativo: [this.listTelefone ? v.ativo : true],
        numero: [this.listTelefone ? v.numero : null],
        id: [this.listTelefone ? v.id : null],
        instalarAppCliente: [this.listTelefone ? v.instalarAppCliente : null],
        possuiInternet: [this.listTelefone ? v.possuiInternet : null],
        tipoSmartphone: [this.listTelefone ? v.tipoSmartphone : null]
      });
    });
    return this.formBuilder.array(phone);
  }

  buildContaBancaria(): FormArray {
    const registrosPixMapper = (registro) => this.formBuilder.group({
      id: registro.id,
      chavePix: registro.chavePix,
      tipoChavePix: registro.tipoChavePix
    });

    const contaBancariaMapper = (conta) => this.formBuilder.group({
      id: conta.id,
      numero: conta.numero,
      agencia: conta.agencia,
      banco: conta.banco,
      tipo: conta.tipo,
      favorecido: conta.favorecido,
      tipoDocumento: conta.tipoDocumento,
      cpf: conta.cpf,
      cnpj: conta.cnpj,
      principal: conta.principal,
      contaTitular: conta.contaTitular,
      parentesco: conta.parentesco,
      registrosPix: this.formBuilder.array(conta.registrosPix.map(registrosPixMapper))
    });

    return this.formBuilder.array(this.listContaBancaria.map(contaBancariaMapper));
  }

  get phoneForms(): FormArray {
    return this.cliente.get('pessoa').get('listTelefone') as FormArray;
  }

  deletePhone(index: number): void {
    this.phoneForms.removeAt(index);
  }

  buildAddress(listEndereco, required): FormArray {
    const address = listEndereco.map(a => {
      return this.formBuilder.group({
        id: new FormControl(listEndereco ? a.id : null),
        cep: new FormControl(listEndereco ? a.cep : null, required ? Validators.required : null),
        logradouro: new FormControl(listEndereco ? a.logradouro : null),
        numero: new FormControl(listEndereco ? a.numero : null, required ? Validators.required : null),
        latitude: new FormControl(listEndereco ? a.latitude : null),
        longitude: new FormControl(listEndereco ? a.longitude : null),
        bairro: new FormControl(listEndereco ? (a.bairro ? a.bairro.descricao : null) : null, required ? Validators.required : null),
        municipio: new FormControl(listEndereco ? (a.municipio ? a.municipio.nomeMunicipio : null) : null, required ? Validators.required : null),
        complemento: new FormControl(listEndereco ? a.complemento : null),
        estado: new FormControl(listEndereco ? (a.estado ? a.estado.sigla : null) : null),
        pontoReferencia: new FormControl(listEndereco ? a.pontoReferencia : null),
        tipoEndereco: new FormControl(listEndereco ? a.tipoEndereco : null)
      });
    });
    return this.formBuilder.array(address);

  }

  buildFiador(ref): FormArray {
    // TODO: descomentar quando back retornar a lista
    let fiadores = [];
    if(ref.length == 0){
      const fiador = this.formBuilder.group({
        id: [ null],
        type: ['fi'],
        nomeFiador: [null],
        cpfFiador: [null],
        listEndereco: this.buildAddress(( ['']), false),
        nomeConjuge: [ null],
        cpfConjuge:  [null]
      });
      fiadores.push(fiador);
    }else{
      fiadores = ref.map((fiador) => {
        return this.formBuilder.group({
         id: [fiador.id ? fiador.id : null],
         type: ['fi'],
         nomeFiador: [fiador.nomeFiador ? fiador.nomeFiador : null],
         cpfFiador: [fiador.cpfFiador ? fiador.cpfFiador : null],
         listEndereco: this.buildAddress((fiador.listEndereco || ['']), false),
         nomeConjuge: [fiador.nomeConjuge ? fiador.nomeConjuge : null],
         cpfConjuge:  [fiador.cpfConjuge ? fiador.cpfConjuge : null]
       });
     })
    }

     return this.formBuilder.array(fiadores)
   }

  // buildFiador(ref): FormGroup {
  //   return this.formBuilder.group({
  //     id: [ref.id ? ref.id : null],
  //     type: ['fi'],
  //     nomeFiador: [ref.nomeFiador ? ref.nomeFiador : null],
  //     cpfFiador: [ref.cpfFiador ? ref.cpfFiador : null],
  //     listEndereco: this.buildAddress((ref.listEndereco || ['']), false),
  //     nomeConjuge: [ref.nomeConjuge ? ref.nomeConjuge : null],
  //     cpfConjuge: [ref.cpfConjuge ? ref.cpfConjuge : null]
  //   });
  // }
  

  buildReferenciaPessoal(listReferencia): FormArray {
    const referencia = listReferencia.map(a => {
      return this.formBuilder.group({
        id: new FormControl(this.listReferencia ? a.id : null),
        nome: new FormControl(this.listReferencia ? a.nome : null, Validators.required),
        situacao: new FormControl(this.listReferencia ? a.situacao : null, Validators.required),
        type: new FormControl('rp'),
        listTelefone: new FormControl(this.listReferencia ? this.buildPhoneReference(a.listTelefone, true) : this.buildPhoneReference([''], true)),
      });
    });
    return this.formBuilder.array(referencia);
  }
  buildSocio(listSocio): FormArray {
    const socio = listSocio.map(a => {
      return this.formBuilder.group({
          // tslint:disable-next-line:triple-equals
          nome: new FormControl(a ? a.nome : null, this.isCpf == false ? Validators.required : null),
          id: new FormControl(a ? a.id : null),
          sexo: new FormControl(a ? a.sexo : null),
          dataNascimento: new FormControl(a ? new Date(a.dataNascimento ? a.dataNascimento : '') : null),
          // tslint:disable-next-line:triple-equals
          cpf: new FormControl(a ? a.cpf : null,  this.isCpf == false ? Validators.required : null),
          // tslint:disable-next-line:triple-equals
          rg: new FormControl(a ? a.rg : null,  this.isCpf == false ? Validators.required : null),
          estadoCivil: new FormControl(a ? a.estadoCivil : null),
          // tslint:disable-next-line:triple-equals
          listTelefone: this.buildPhoneReference(a ? a.listTelefone : [],  this.isCpf == false ? true : null),
          // tslint:disable-next-line:triple-equals
          listEndereco: this.buildAddress(a ? a.listEndereco : [],  this.isCpf == false ? true : null),
          type: 'pf'
      });
    });
    return this.formBuilder.array(socio);
  }

  referencia(listReferencia): FormGroup {
    listReferencia.forEach(element => {
      // tslint:disable-next-line:triple-equals
      if (element.type == 'rc') {
        this.referenciaComercial = element;
      // tslint:disable-next-line:triple-equals
      } else if (element.type == 'rb') {
        this.referenciaBancaria = element;
      // tslint:disable-next-line:triple-equals
      } else if (element.type == 'fi') {
        this.referenciaFiador.push(element);
      }
    });

    return this.formBuilder.group({
      referenciaComercial: this.buildContactComercial(this.referenciaComercial || ''),
      referenciaBancaria: this.buildBanking(this.referenciaBancaria || ''),
      referenciaFiador: this.buildFiador(this.referenciaFiador)
    });
  }

  buildContactComercial(ref): FormGroup {
    return this.formBuilder.group({
      id: [ref.id ? ref.id : null],
      type: ['rc'],
      nome: [ref.nome ? ref.nome : null],
      listTelefone: this.buildPhoneReference(ref.listTelefone ? ref.listTelefone : ['']),
      situacao: [ref.situacao]
    });
  }

  buildBanking(ref): FormGroup {
    return this.formBuilder.group({
      id: [ref.id ? ref.id : null],
      type: ['rb'],
      nome: [ref.nome ? ref.nome : null],
      listTelefone: this.buildPhoneReference(ref.listTelefone ? ref.listTelefone : ['']),
      credito: [ref.credito ? ref.credito : null]
    });
  }

 

  buildPhoneReference(listTelefone: any, required?): FormArray {
    const phone = listTelefone.map(tel => {
      return this.formBuilder.group({
        ativo: new FormControl(tel.ativo ? tel.ativo : true),
        numero: new FormControl(tel.numero ? tel.numero : '', required ? Validators.required : null),
        id: new FormControl(tel.id ? tel.id : null)
      });
    });
    return this.formBuilder.array(phone);
  }

  get addressForms(): FormArray {
    return this.cliente.get('pessoa').get('listEndereco') as FormArray;
  }

  get socioForms(): FormArray {
    return this.cliente.get('pessoa').get('listSocio') as FormArray;
  }

  listContract(): void {
    this.contractService.findById(this._data.customer.id).subscribe(data => {
      this.rows = data.object;
    });
  }

  onAdd(customers): void {
    this.disabledButton = true;

    let UF = '';
    let municipio: any;


    customers.pessoa.listEndereco.forEach(element => {
      UF = element.estado;
      municipio = element.municipio;
    });

    delete customers.indicador;
    delete customers.isCpf;

    this.segmentList.forEach(att => {
      // tslint:disable-next-line:triple-equals
      if (att.descricao == customers.atividadeComercial) {
        customers.atividadeComercial = att;
      }
    });

    this.observacoesList.forEach(obs => {
      if (obs.descricao === customers.observacaoCliente) {
        customers.observacaoCliente = obs;
      }
    })

    customers.pessoa.listEndereco.forEach(data => {
      if (data.bairro != null) {
        if (typeof data.bairro === 'object') {
          data.municipio = data.bairro.municipio;
          data.estado = data.bairro.municipio.estado;
        } else {
          this.globalService.bairroByDescricao(data.bairro).subscribe(res => {
            const bairro = res.object.content.filter(b => b.municipio.nomeMunicipio === data.municipio)[0];
            data.bairro = bairro;
            data.municipio = bairro.municipio;
            data.estado = bairro.municipio.estado;
          });
        }
      }
    });

    customers.pessoa.listSocio.forEach(s => {
      s.listEndereco.forEach(data => {
        if (data.bairro != null) {
          if (typeof data.bairro === 'object') {
            data.municipio = data.bairro.municipio;
            data.estado = data.bairro.municipio.estado;
          } else {
            this.globalService.bairroByDescricao(data.bairro).subscribe(res => {
              const bairro = res.object.content.filter(b => b.municipio.nomeMunicipio === data.municipio)[0];
              data.bairro = bairro;
              data.municipio = bairro.municipio;
              data.estado = bairro.municipio.estado;
            });
          }
        }
      });
    });


    customers.referenciaPessoal.forEach(r => {
      r.listTelefone = r.listTelefone.value;
      this.listReferencia.push(r);
    });

    this.listReferencia.push(customers.listReferencia.referenciaComercial);
    this.listReferencia.push(customers.listReferencia.referenciaBancaria);
    this.listReferencia.push(customers.listReferencia.referenciaFiador);

    customers.listReferencia = this.listReferencia;

    customers.pessoa.listEndereco.forEach(element => {
      this.googleMaps(element);
    });

    if (customers.pessoa.isCpf === true) {
      customers.pessoa.type = 'pf';
    } else {
      customers.pessoa.type = 'pj';
    }

    this.handleListContaBancaria(customers);
    customers.posto = this.captadorPosto;

    setTimeout(() => {
      this.create(customers);
    }, 2500);

  }


  onUpdate(customers: any): void {
    this.disabledButton = true;

    if (customers.pessoa.isCpf) {
      customers.pessoa.cnpj = null;
      customers.pessoa.nomeFantasia = null;
    } else {
      customers.pessoa.cpf = null;
    }

    let UF = '';
    let municipio: any;

    customers.pessoa.listEndereco.forEach(element => {
      UF = element.estado;
      municipio = element.municipio;
    });

    delete customers.indicador;
    delete customers.isCpf;

    this.uploadSPC = customers.file;
    delete customers.file;

    this.segmentList.forEach(att => {
      if (att.descricao === customers.atividadeComercial) {
        customers.atividadeComercial = att;
      }
    });

    this.observacoesList.forEach(obs => {
      if (obs.descricao === customers.observacaoCliente) {
        customers.observacaoCliente = obs;
      }
    })

    this.listReferencia = [];
    customers.referenciaPessoal.forEach(r => {
      r.listTelefone = r.listTelefone.value;
      this.listReferencia.push(r);
    });

    customers.pessoa.listSocio.forEach(s => {
      s.listEndereco.forEach(data => {
        if (data.bairro != null) {
          if (typeof data.bairro === 'object') {
            data.municipio = data.bairro.municipio;
            data.estado = data.bairro.municipio.estado;
          } else {
            this.globalService.bairroByDescricao(data.bairro).subscribe(res => {
              const bairro = res.object.content.filter(b => b.municipio.nomeMunicipio === data.municipio)[0];
              data.bairro = bairro;
              data.municipio = bairro.municipio;
              data.estado = bairro.municipio.estado;
            });
          }
        }
      });
    });

    this.listReferencia.push(customers.listReferencia.referenciaComercial);
    this.listReferencia.push(customers.listReferencia.referenciaBancaria);
    
    customers.listReferencia.referenciaFiador.forEach((fiador) => {
      console.log(fiador);
      fiador.listEndereco.forEach(end => {
        if (end.bairro != null) {
          if (typeof end.bairro === 'object') {
            end.municipio = end.bairro.municipio;
            end.estado = end.bairro.municipio.estado;
          }else{
            this.globalService.bairroByDescricao(end.bairro).subscribe(res => {
              const bairro = res.object.content.filter(b => b.municipio.nomeMunicipio === end.municipio)[0];
              end.bairro = bairro;
              end.municipio = bairro.municipio;
              end.estado = bairro.municipio.estado;
            });
          }
        }
      })
    })
    customers.listReferencia.referenciaFiador.forEach(fiador => {
      this.listReferencia.push(fiador); 
    });
    

    customers.listReferencia = this.listReferencia;

    if (customers.pessoa.isCpf === true) {
      customers.pessoa.type = 'pf';
    } else {
      customers.pessoa.type = 'pj';
    }

    customers.pessoa.listEndereco.forEach(data => {
      if (data.bairro != null) {
        if (typeof data.bairro === 'object') {
          data.municipio = data.bairro.municipio;
          data.estado = data.bairro.municipio.estado;
        } else {
          this.globalService.bairroByDescricao(data.bairro).subscribe(res => {
            const bairro = res.object.content.filter(b => b.municipio.nomeMunicipio === data.municipio)[0];
            data.bairro = bairro;
            data.municipio = bairro.municipio;
            data.estado = bairro.municipio.estado;
          });
        }
      }
    });

    customers.posto = this.captadorPosto == null ? this._data.customer.posto : this.captadorPosto;

    this.handleListContaBancaria(customers);

    setTimeout(() => {
      this.update(customers);
    }, 2500);
  }

  private handleListContaBancaria(customers): void {
    const contas = customers.pessoa.listContaBancaria;
    // tslint:disable-next-line: radix
    if (contas.length === 1 && !contas[0].id && !contas[0].agencia && !contas[0].numero) {
      customers.pessoa.listContaBancaria = [];
    }
  }


  private create(object: any): void {
    this.clienteService.addCliente(object).subscribe(
      (data) => {
        if (data.success) {
          this.disabledButton = true;
          this.matDialogRef.close(data.object);

          this.dialogMessages = {
            type: 'success',
            title: 'Sucesso!',
            text: `${data.messages[0].text}`
          };
          this.feedback(this.dialogMessages);


        } else {
          this.disabledButton = false;
          if (data.messages[0].text != null) {
            let message = data.messages[0].text;
            if (message.includes('messageTemplate')) {
              message = message.substring(
                message.lastIndexOf('=') + 2,
                // tslint:disable-next-line:quotemark
                message.lastIndexOf("'")
              );
            } else {
              message = message.includes(' - ') ? `${message.split(' - ', 2)[1]}` : message;
            }
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: message
            };
          } else {
            this.dialogMessages = {
              type: 'error',
              title: 'Error!',
            };
          }
          this.feedback(this.dialogMessages);
        }
      },
      (onerror) => {
        this.disabledButton = false;
        console.log(onerror);
      }
    );
  }

  private update(object: any): void {
    console.log (object);
    this.clienteService.editCliente(object).subscribe(
      (data) => {
        if (data.success) {
          this.matDialogRef.close(data.object);

          this.dialogMessages = {
            type: 'success',
            title: 'Sucesso!',
            text: `${data.messages[0].text}`
          };
          this.feedback(this.dialogMessages);

          this.referenciasPixFormComponent.saveReferencias().subscribe(
            (response) => {
              if (response.success) {
                this.dialogMessages = {
                  type: 'success',
                  title: 'Sucesso!',
                  text: `Referencias Pix Atualizadas com Sucesso!`,
                };
                this.feedback(this.dialogMessages);
              } else {
                this.utilsService.feedbackError(
                  'Ocorreu um Erro ao Atualizar as Referencias Pix, \n Referencias poderão ser atualizadas na liberação de contrato!', 
                  TimeMensagem.TIME_MENSAGEM
                );
              }
            }
          );

          if (this.uploadSPC) {
            this.clienteService.uploadArquivoSPC(this.uploadSPC).subscribe((responseApi: ResponseAPI) => {
              if (responseApi.success) {
                this.utilsService.feedbackSuccess('Upload do arquivo SPC Realizado Com Sucesso!', 3000);
              } else {
                this.utilsService.feedbackError('Error', 3000);
              }
            },
            (error: HttpErrorResponse) => {
              this.utilsService.feedbackError(`Error <br/> ${error.message}`, 3000);
            });
          }

        } else {
          if (data.messages[0].text != null) {
            let message = data.messages[0].text;
            if (message.includes('messageTemplate')) {
              message = message.substring(
                message.lastIndexOf('=') + 2,
                // tslint:disable-next-line:quotemark
                message.lastIndexOf("'")
              );
            } else {
              message = message.includes(' - ') ? `${message.split(' - ', 2)[1]}` : message;
            }
            this.dialogMessages = {
              type: 'info',
              title: 'Informação!',
              text: message
            };
          } else {
            this.dialogMessages = {
              type: 'error',
              title: 'Error!',
            };
          }
          this.feedback(this.dialogMessages);
          this.disabledButton = false;
        }
      },
      (onerror) => {
        console.log(onerror);
      }
    );
  }

  onCancel(): void {
    this._matDialog.closeAll();

  }

  onClosed(): void {
    if (this.editedCaptacao) {
      this.matDialogRef.close({ captacao: this.editedCaptacao, success: true });
    } else {
      this.matDialogRef.close();
    }
  }

  setStep(index: number): void {
    this.step = index;
  }

  nextStep(): void {
    this.step++;
  }

  prevStep(): void {
    this.step--;
  }

  getErrorMessage(field: string): string {
    return this.cliente.get(field).hasError('required') ? `O campo ${field} é obrigatório` : '';
  }

  private feedback(message: string[]): void {
    const dialogRef = this._matDialog.open(MessageDialogComponent, {
      data: message,
    });
    setTimeout(() => dialogRef.close(), 8000);
  }

  getEmployeeLogged(event): void {
    this.loggedEmployee = event;
  }


  googleMaps(customer): void {
    const geocoder = new google.maps.Geocoder();
    const address = `${customer.logradouro}, ${customer.numero}, ${customer.municipio ? customer.municipio.nomeMunicipio : ''}, ${customer.estado}, ${customer.cep}`;
    geocoder.geocode({ 'address': address }, (results, status) => {
      if (status === 'OK') {
        customer.logradouroCompleto = results[0].formatted_address;
        customer.latitude = results[0].geometry.location.lat();
        customer.longitude = results[0].geometry.location.lng();
      }
    });
  }

  checkIfHasPosto(): void {
    if (sessionStorage.getItem('funcionario')) {
      const user = JSON.parse(sessionStorage.getItem('funcionario'));
      const postoId = user.idPosto ? user.idPosto : 0;
      this.postoService.findById(postoId).subscribe(r => {
        this.captadorPosto = r.object;
      });
    }
  }

  checkReferenciaPixFormInvalid(invalido: boolean): void {
    this.referenciaPixFormInvalido = invalido;
  }
  generateMask(value: number): string {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

}
