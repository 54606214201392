import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { Mask } from '@fagnerlima/ng-mask';

@Component({
  selector: 'app-fiador',
  templateUrl: './fiador.component.html',
  styleUrls: ['./fiador.component.scss'],
  animations: [fuseAnimations]
})
export class FiadorComponent implements OnInit {

  @Input() visit: FormGroup;
  maskCpf = new Mask('000.000.000-00');
  maskVencimento = new Mask('00/00');
  maskCartao = new Mask('0000000000000000');
  
  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void { }

  get fiadoresForms(): FormArray {
    return this.visit.get('listReferencia.referenciaFiador') as FormArray 
  }

  get addressForms(): FormArray {
    return this.visit.get('listReferencia').get('referenciaFiador').get('listEndereco') as FormArray;
  }


  addFiador(required?: boolean): void {
    const fiador = this.formBuilder.group({
      id: [],
      type: ['fi'],
      nomeFiador: [null],
      cpfFiador: [null],
      listEndereco: this.buildAddress(),
      listaEndereco: [],
      nomeConjuge: [null],
      cpfConjuge:  [null]
    });
    this.fiadoresForms.push(fiador);
  };


  buildAddress(): FormArray {
    return this.formBuilder.array([
      this.formBuilder.group({
        id: new FormControl(null),
        cep: new FormControl(null),
        logradouro: new FormControl(null),
        numero: new FormControl(null),
        latitude: new FormControl(null),
        longitude: new FormControl(null),
        bairro: new FormControl(null),
        municipio: new FormControl(null),
        complemento: new FormControl(null),
        estado: new FormControl(null),
        pontoReferencia: new FormControl(null),
        tipoEndereco: new FormControl(null)
      })
    ]);

  }

  deleteFiador(index: number): void {
    this.fiadoresForms.removeAt(index);

  }


  getErrorMessage(field: string): string {
    return `O campo ${field} é obrigatório`;
  }

}
